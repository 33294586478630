<template>
  <div
    id="nav"
    :class="headerClasses"
  >
    <div>
      <TopHeader
        v-bind="socialLinks"
      />
      <BottomHeader :class="{ 'scrolled': isScrolled }" :isBlueHeader="isBlueHeader" />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  components: {
    TopHeader: defineAsyncComponent(() => import('@/components/Common/Client/TopHeader.vue')),
    BottomHeader: defineAsyncComponent(() => import('@/components/Common/Client/BottomHeader.vue')),
  },
  data() {
    return {
      isScrolled: false,
      socialLinks: {
        whatsapp_url: '',
        twitter_url: '',
        facebook_url: '',
        linkedIn_url: '',
        youtube_url: '',
        instagram_url: '',
        telegram_url: '',
        snapchat_url: '',
      },
    };
  },
  computed: {
    headerClasses() {
      return {
        'light-header': this.isLightHeader,
        'blue-header': this.isBlueHeader,
      };
    },
    isLightHeader() {
      return ['error', 'profile'].some(path => this.$route.path.includes(path));
    },
    isBlueHeader() {
      const blueHeaderPaths = [
        'faqs',
        'reset-password',
        'privacy-policy',
        'using-policy',
        'details',
        'banks',
        'jobs',
        'downloads-center',
        'order',
        'login',
        'register',
        'forgot',
        '/commercial-franchise',
        '/thanks',
        '/verification',
      ];
      return blueHeaderPaths.some(path => this.$route.path.includes(path));
    },
    showHeaderTop() {
      return this.$route.path === '/';
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 50;
    },
  },
};
</script>

<style lang="scss"></style>
